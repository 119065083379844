import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import favicon from './assets/favicon.ico'; // Import the favicon
import reportWebVitals from './reportWebVitals';

// Set favicon and title dynamically
const setFaviconAndTitle = () => {
  // Set the favicon
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = favicon;
  document.head.appendChild(link);

  // Set the title
  document.title = 'Millennium Commercial Properties';
};

// Call the function to set the favicon and title
setFaviconAndTitle();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

