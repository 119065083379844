import React, { useState } from 'react';
import logo from './assets/logo.png';  // Ensure your logo is correctly referenced

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/mwpkpoqw', { // Replace 'myformID' with your actual Formspree ID
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });

      if (response.ok) {
        setSubmitted(true);
        setFormData({ name: '', email: '', message: '' });
      } else {
        alert('There was a problem submitting the form.');
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-100">
      {/* Header Section */}
      <header className="bg-white py-6">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-xl font-bold">Millennium Commercial Properties LLC</h1>
          <nav className="space-x-6">
            <a href="https://www.millenniumcommercial.com/" className="text-gray-600 hover:underline">Home</a>
            <a href="https://www.millenniumcommercial.com/team?pgid=kxnxhod3-6dc172e5-7b3e-42fc-94b1-b11eea8845a3" className="text-gray-600 hover:underline">Team</a>
          </nav>
        </div>
      </header>

      {/* Main Section */}
      <main className="flex-grow">
        <section className="text-center py-16 bg-white">
          <h2 className="text-4xl font-bold mb-8">Have questions or need support?</h2>
          {submitted ? (
            <div className="mt-8 mx-auto max-w-md text-green-500">
              <p>Thank you! Your message has been sent successfully.</p>
            </div>
          ) : (
            <div className="mt-8 mx-auto max-w-md">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-left mb-2 text-gray-700 font-semibold">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div>
                  <label className="block text-left mb-2 text-gray-700 font-semibold">E-mail</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="Enter your e-mail"
                    required
                  />
                </div>
                <div>
                  <label className="block text-left mb-2 text-gray-700 font-semibold">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    placeholder="Your message..."
                    rows="4"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="px-6 py-2 bg-orange-400 text-white rounded-lg hover:bg-orange-500">
                  Contact Us
                </button>
              </form>
            </div>
          )}
        </section>
      </main>

      {/* Footer Section */}
      <footer className="bg-green-200 py-8">
        <div className="container mx-auto text-center">
          <img src={logo} alt="Millennium Commercial Properties" className="mx-auto mb-4" style={{ height: '300px' }} />
          <h3 className="text-4xl font-semibold">Jeff Chain</h3>
          <p className="font-semibold">CEO/Broker</p>
          <p>Millennium Commercial Properties LLC</p>
          <p className="mt-2 text-lg">+1 702-379-8855 | Jeff@mpdnv.com</p>
          <p>B:26141NV</p>
        </div>
      </footer>
    </div>
  );
};

export default ContactPage;

